.services {}
  .services .content {
    text-align: left;
    margin: 20px 0;
    padding: 0 40px;
  }
    .services .meals {
        display: flex;
        justify-content: space-around;
    }
      .services .meals .meal {
          margin: 10px 15px;
      }

@media only screen and (max-width: 650px) {
  .services .content {
    padding: 0 20px;
  }
}
