.contact {
}
  .contact .content {
    text-align: left;
    margin: 20px 0;
    padding: 0 40px;
  }
    .contact .content h2 {
      margin-bottom: 0;
    }
    .contact .content p {
      margin-top: 0;
    }

@media only screen and (max-width: 650px) {
  .contact .content {
    padding: 0 20px;
  }
}
